import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../components/mdx/page";
import { ButtonPrimary, ButtonSecondary } from "../../components/widgets/button";
import { ContentPlusDecor } from "../../components/widgets/content-plus-decor";
import { BsEnvelopeCheck } from "react-icons/bs";
import { useMembership } from "../../utils/member-data";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Thank You for Joining...`}</h1>
    <ContentPlusDecor decor={<BsEnvelopeCheck mdxType="BsEnvelopeCheck" />} mdxType="ContentPlusDecor">
      <h2>{`... Now Check Your Inbox!`}</h2>
      <p>{`We just sent you an email to complete the sign-up process and set your password. Please check your spam folder if you haven't received it within a few minutes.`}</p>
      <p>{`Having difficulties? Please do not hesitate to reach out for assistance.`}</p>
      <ButtonSecondary text="Contact Us" to="/company/contact/" mdxType="ButtonSecondary" />
    </ContentPlusDecor>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      